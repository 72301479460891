<template>
  <b-card>
    <div>
      <b-table striped hover :items="items">
        <template #cell(detalle)="row">
          <ul>
            <li v-for="d in row.item.detalle">{{ d.mensaje }}</li>
          </ul>

        </template>
        <template #cell(descargable)="row">
          <b-button size="sm" class="mr-2" @click="downloadItem(row.item)" :disabled="row.item.descargable == null">
            Ver
          </b-button>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BTabs, BTab, BImg, BRow, BCol, BAvatar, BTable, BLink
} from 'bootstrap-vue'

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    BTable,
    BLink
  },
  data() {
    return {
      items: [
        {
          Acción: 'Mejoras realizadas', fecha: '23/03/2023', nombre: 'Primera mejora - Marzo 2023',
          detalle: [
            { mensaje: 'Se cambió la arquitectura de los modelos de multi input - multi output a multi input – uni output.' },
            { mensaje: 'Se reentrenaron los modelos con data desde Enero 2018 a Enero 2022.' },
            { mensaje: 'Se calcularon los errores en peaks obteniendo un promedio para el 10% más alto y más bajo de 1,98% (valor anterior 4,00%).' }
          ],
          descargable: 'https://drive.google.com/file/d/1x-Fx45Vvp_rYDtE8MMd0ZxFKnVBje1cv/view?usp=drive_link'
        },
        {
          Acción: 'Mejoras realizadas', fecha: '10/05/2023', nombre: 'Segunda mejora - Mayo 2023',
          detalle: [
            { mensaje: 'Se reentrenaron los modelos con data de Enero del 2012 hasta Abril del 2023.' },
            { mensaje: 'Se calcularon los errores en peaks obteniendo un promedio para el 10% más alto y más bajo de 1,22%.' },
            { mensaje: 'Se agregaron los tooltips en cada uno de los ítems de la plataforma.' },
            { mensaje: 'Se agregaron las preguntas frecuentes y términos y condiciones a la plataforma en sección “Términos y Condiciones”.' }
          ],
          descargable: null
        },
        {
          Acción: 'Mejoras realizadas', fecha: '13/06/2023', nombre: 'Tercera mejora - Junio 2023',
          detalle: [
            { mensaje: 'Se crearon los puntos de diferencia en plataforma e informativo general para una mejor medición de la precisión del modelo.' },
            { mensaje: 'Se realizaron mejoras en la visualización de las predicciones dentro de la plataforma.' },
            { mensaje: 'Se agregaron nuevas tarjetas en la vista de predicciones – real vs predicho para un mejor entendimiento promedio de asertividad y puntos de diferencias.' },
            { mensaje: 'Se agregaron a las preguntas frecuentes GIFTs de apoyo para un mejor entendimiento de la plataforma Bruna.' }
          ],
          descargable: null
        },
        {
          Acción: 'Informativo resumen AiBruna', fecha: '19/06/2023', nombre: 'Informativo Junio 2023',
          detalle: [
            { mensaje: 'Contexto.' },
            { mensaje: 'Resultados generales reentrenamiento.' },
            { mensaje: 'Resultados generales predicciones.' }
          ],
          descargable: 'https://drive.google.com/file/d/1WboQb9TzLp4g3M9bKK2QNQpwQDVOr2Np/view?usp=drive_link'
        },
      ]

    }
  },
  watch: {

  },
  methods: {
    downloadItem(a) {
      const url = a.descargable;
      if (a.descargable != null) {
        window.open(url, '_blank');
      }
    },


  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>